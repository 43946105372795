import { makeStyles, createStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      [theme.breakpoints.down('sm')]: {
        '& .MuiButton-root': {
          display: 'block',
          margin: 'auto',
        },
      },
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'column',
      },
      [theme.breakpoints.up('md')]: {
        display: 'inline-flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: theme.shape.borderRadius,
        gap: theme.spacing(1),
      },
      [theme.breakpoints.up('xl')]: {
        height: '100%',
        gap: theme.spacing(2),
      },
    },
    daterangeOneDay: {
      display: 'flex',
      background: theme.palette.common.white,
      boxShadow: theme.shadows[1],
      borderRadius: theme.shape.borderRadius,
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        margin: theme.spacing(0, 0, 1, 0),
      },
    },
    order1: {
      position: 'relative',
      [theme.breakpoints.down('md')]: {
        order: 1,
      },
    },
    order2: {
      [theme.breakpoints.down('md')]: {
        order: 2,
      },
    },
    shopLayout: {
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
      },
    },
    shopLayoutLanding: {
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-start',
      },
    },
    loaderOverlay: {
      position: 'absolute',
      zIndex: theme.zIndex.appBar,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'rgba(255,255,255, .5)',
      backdropFilter: 'blur(2px)',
    },
    loaderSizer: {
      height: '100%',
    },
    whenLoading: {
      overflow: 'hidden',
      '&:hover': {
        cursor: 'wait',
      },
    },
    when: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.primary.main,
      boxShadow: theme.shadows[1],
      borderRadius: theme.shape.borderRadius,
      background: theme.palette.common.white,
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        flexWrap: 'wrap',
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(1),
      },
      [theme.breakpoints.up('lg')]: {
        margin: theme.spacing(0, 1.5, 0, 0),
      },
    },
    whenOneDay: {
      boxShadow: 'none',
      [theme.breakpoints.up('lg')]: {
        margin: 0,
      },
    },
    when__icon: {
      marginLeft: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        position: 'absolute',
        marginLeft: 0,
        left: theme.spacing(1),
      },
    },
    buttonHeight: {
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    iconArrow: {
      color: theme.palette.primary.main,
    },
    datepicker: {
      fontSize: 16,
      height: 48,
      borderRadius: theme.shape.borderRadius,
      color: theme.palette.primary.main,
      textAlign: 'center',
      border: 0,
      cursor: 'pointer',
      '&:focus': {
        outline: 'none',
      },
      [theme.breakpoints.down('md')]: {
        minWidth: 120,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        appearance: 'none',
        '& input': {
          color: theme.palette.primary.main,
          margin: 0,
          padding: theme.spacing(0.5, 0, 0, 1),
          '&:webkit-calendar-picker-indicator': {
            display: 'none',
          },
        },
        '& .MuiInputBase-root:before, & .MuiInputBase-root:after': {
          display: 'none',
        },
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 14,
        fontFamily: theme.typography.fontFamily,
      },
    },
    datePickerText: {
      maxWidth: 300,
      margin: 'auto',
      color: theme.palette.secondary.main,
      textAlign: 'center',
      fontSize: '11px',
      padding: theme.spacing(0.5),
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
      '&:empty': {
        display: 'none',
      },
    },
    datePickerWithText: {
      '& .react-datepicker': {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
    backgroundSolid: {
      backgroundColor: theme.extraColors.greyLight,
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
    },
    mobileInput: {
      fontFamily: 'inherit',
      borderRadius: 0,
      background: 'none',
      color: theme.palette.primary.main,
      width: '100%',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      '&:hover,&:focus': {
        background: 'none',
      },
      [theme.breakpoints.up('md')]: {},
    },
    btnSubmit: {
      '&[disabled]': {
        cursor: 'wait',
        '&:hover,&:focus': {
          cursor: 'wait',
        },
      },
    },
    show: {
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[1],
      animation: '$calendar-show .4s',
      willChange: 'transform, opacity',
    },
    hide: {
      animation: '$calendar-hide .2s',
      willChange: 'transform, opacity',
    },
    '@keyframes calendar-show': {
      '0%': {
        opacity: 0,
        transform: 'translate3d(0, -1px, 0)',
      },
      '100%': {
        opacity: 1,
        transform: 'translate3d(0, 0, 0)',
      },
    },
    '@keyframes calendar-hide': {
      '0%': {
        opacity: 1,
      },
      '100%': {
        opacity: 0,
      },
    },
    formControl: {
      minWidth: 100,
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      [theme.breakpoints.up('lg')]: {
        borderLeft: `1px solid ${theme.extraColors.greyLight}`,
      },
    },
    dayDurationSelctor: {
      fontSize: 14,
      color: theme.palette.primary.main,
      background: theme.palette.common.white,
      //appearance: 'none',
      textAlign: 'center',
      fontFamily: theme.typography.fontFamily,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(1, 1),
      marginRight: theme.spacing(2),
      minWidth: 100,
      height: '100%',
      border: 'none',
      [theme.breakpoints.down('md')]: {
        minWidth: 50,
        margin: 0,
      },
    },
  })
)

export default useStyles
